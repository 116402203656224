.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: var(--preloader);
  background-image: url(../../../assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}